import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Die neue Version 2.2 der `}<ItalicText mdxType="ItalicText">{`Web Content Accessibility Guidelines`}</ItalicText>{` wurde vor kurzem veröffentlicht.
Sie definiert 9 neue Erfolgskriterien und streicht das alte Kriterium `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/parsing.html"
      }}>{`4.1.1 Parsing`}</a>{`.`}</p>
    <p>{`Ich werde euch nicht mit einem weiteren `}<ItalicText mdxType="ItalicText">{`„WCAG 2.2 ist endlich da!“`}</ItalicText>{`-Artikel langweilen. Andere
Expert:innen für Barrierefreiheit, die mehr Erfahrung haben als ich, haben bereits die Änderungen im Vergleich zu WCAG 2.1
erläutert (siehe die Links am Ende).`}</p>
    <p>{`Stattdessen möchte ich mich auf einzelne neue Kriterien konzentrieren und euch praktische Beispiele geben, wie ihr diese
erfüllen könnt.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFj4KC1Bgn/xAAbEAABBAMAAAAAAAAAAAAAAAABAAIDBBEiMv/aAAgBAQABBQKeXAhILVZbpW4X/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/ATF//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAAREAAhBR/9oACAEBAAY/Al2VICGOUz//xAAbEAABBAMAAAAAAAAAAAAAAAAAAREhMRBBYf/aAAgBAQABPyG3NQShKRGxhzmpSx//2gAMAwEAAgADAAAAEC8//8QAFxEBAAMAAAAAAAAAAAAAAAAAARAhUf/aAAgBAwEBPxBNNj//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EDW4/8QAHhABAAICAQUAAAAAAAAAAAAAAQARIUExUXGBkaH/2gAIAQEAAT8QzZDVfG4ZTcLZaX77lesAk5xHeXSNK8+YgKE//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Das Gesicht einer Frau, das zur Hälfte von einem Buch mit grünem Umschlag verdeckt wird.",
          "title": "Das Gesicht einer Frau, das zur Hälfte von einem Buch mit grünem Umschlag verdeckt wird.",
          "src": "/static/d9427ba0d382870163ce8912729e1ee3/e5166/pexels-george-milton-hiding-behind-book.jpg",
          "srcSet": ["/static/d9427ba0d382870163ce8912729e1ee3/f93b5/pexels-george-milton-hiding-behind-book.jpg 300w", "/static/d9427ba0d382870163ce8912729e1ee3/b4294/pexels-george-milton-hiding-behind-book.jpg 600w", "/static/d9427ba0d382870163ce8912729e1ee3/e5166/pexels-george-milton-hiding-behind-book.jpg 1200w", "/static/d9427ba0d382870163ce8912729e1ee3/b17f8/pexels-george-milton-hiding-behind-book.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © George Milton / pexels.com`}</em></p>
    <h2>{`Die neuen Kriterien der WCAG 2.2`}</h2>
    <p>{`Mehrere neue Kriterien zielen darauf ab, die Bedienbarkeit von Websites und Apps zu verbessern: Fokus-Indikatoren sollten
klar erkennbar sein und die fokussierten Bedienelemente sollten nicht durch andere Inhalte auf der Seite verdeckt werden.`}</p>
    <p>{`Die Bedienelemente sollten so groß sein, dass sie leicht aktiviert werden können, ohne versehentlich ein benachbartes Ziel zu
aktivieren. Wenn eine Website Funktionalitäten anbietet, die eine Ziehbewegung zur Bedienung verwenden, dann muss sie eine
alternative Möglichkeit für Maus- und Tastatur-Nutzer:innen anbieten.`}</p>
    <p>{`Hier ist eine Liste aller neuen Erfolgskriterien, die sich auf die Bedienbarkeit beziehen:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-minimum.html"
        }}>{`2.4.11 Fokus nicht verdeckt (Minimum) (AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-enhanced.html"
        }}>{`2.4.12 Fokus nicht verdeckt (Erweitert) (AAA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance.html"
        }}>{`2.4.13 Fokus-Erscheinungsbild (AAA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/dragging-movements.html"
        }}>{`2.5.7 Ziehbewegungen (AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/target-size-minimum.html"
        }}>{`2.5.8 Zielgröße (Minimum) (AA)`}</a></li>
    </ul>
    <p>{`Die folgenden Erfolgskriterien zielen darauf ab, die Zugänglichkeit für Menschen mit kognitiven Beeinträchtigungen zu verbessern:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/consistent-help.html"
        }}>{`3.2.6 Konsistente Hilfe (A)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/redundant-entry.html"
        }}>{`3.3.7 Redundante Eingabe (A)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-minimum.html"
        }}>{`3.3.8 Barrierefreie Authentifizierung (Minimum) (AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG22/Understanding/accessible-authentication-enhanced.html"
        }}>{`3.3.9 Barrierefreie Authentifizierung (Erweitert) (AAA)`}</a></li>
    </ul>
    <p>{`Schauen wir uns nun einige praktische Beispiele für die neuen Erfolgskriterien an.`}</p>
    <h2>{`Praktische Beispiele`}</h2>
    <h3>{`Anwendungsfall: Fixierte Kopfzeile`}</h3>
    <p>{`Viele Websites enthalten einen Header, der am oberen Rand der Seite verbleibt, auch wenn die Seite gescrollt wird. Eine gute gemachte,
fixierte Kopfzeile verbessert die Benutzerfreundlichkeit der Website. Stellt euch einen Benutzer vor, der einen
langen Artikel liest: Wenn er am Ende der Seite angelangt ist, muss er nicht wieder nach oben scrollen, um auf die Hauptnavigation
im Header zuzugreifen.`}</p>
    <p>{`Leider kann eine fixierte Kopfzeile allen Nutzer:innen, die keine Maus benutzen können, das Leben schwer machen. Wenn sie auf eine
Website mit der Tastatur navigieren, müssen sie immer sehen, was den Tastaturfokus hat. Eine fixierte Kopf- oder Fußzeile kann dazu
führen, dass fokussierte Elemente hinter diesen Elementen verschwinden.`}</p>
    <p>{`Doch es gibt eine einfache und elegante Lösung für dieses Problem: Die CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`scroll-padding`}</InlineCode>{`
ermöglicht es, Offsets für den optimalen Sichtbereich des Scrollports zu definieren — den Bereich, der als Zielbereich für die
Platzierung von Elementen im Sichtfeld des Benutzers verwendet wird. Ihr braucht nur folgendes:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`html {
    scroll-padding: 4rem 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Hier ist eine Demo einer Website mit fixierter Kopf- und Fußzeile. Versucht mit der Tabulatortaste zu navigieren und achtet
darauf, wie der Browser den fokussierten Link im sichtbaren Bereich hält:`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/scrollpadding" title="Scroll Padding Demo" loading="lazy"></iframe>
    <p>{`Auf diese Weise erfüllt die Website die Kriterien `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-minimum.html"
      }}>{`2.4.11 Fokus nicht verdeckt (Minimum) (AA)`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-not-obscured-enhanced.html"
      }}>{`2.4.12 Fokus nicht verdeckt (Erweitert) (AAA)`}</a>{`.`}</p>
    <p>{`Eine weitere verwandte CSS-Eigenschaft ist `}<InlineCode mdxType="InlineCode">{`scroll-margin`}</InlineCode>{`. Ihr könnt sie verwenden, um z.B. Inhalte unter
einem Banner mit fester Position freizulegen, wie die WCAG-Technik C43 zeigt:
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Techniques/css/C43"
      }}><span lang="en">{`„Using CSS margin and scroll-margin to un-obscure content“`}</span></a>{`.`}</p>
    <h3>{`Anwendungsfall: Interaktive Karte mit Ziehen-zum-Verschieben`}</h3>
    <p>{`Bei einer interaktiven Karte können Nutzer:innen normalerweise die Ansicht der Karte mit der Maus oder durch Berührung verschieben.
Aber manche Menschen können Ziehbewegungen nicht präzise ausführen. Oder sie verwenden ein spezielles Eingabegerät, z.B. einen
Trackball oder einen Kopfzeiger, was die Ziehbewegung umständlich und fehleranfällig macht.`}</p>
    <p>{`Eine barrierefreie Karte sollte auch Schaltflächen zum Verschieben der Ansicht nach oben/unten/links/rechts enthalten, um dem
Erfolgskriterium `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/dragging-movements.html"
      }}>{`2.5.7 Ziehbewegungen (AA)`}</a>{` zu entsprechen.
Nutzer:innen mit motorischen Beeinträchtigungen können die Steuerelemente einfach mit der Tabulatortaste ansteuern und den
Kartenausschnitt damit verschieben.`}</p>
    <p>{`Seht euch meine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/cascade-layers-angular-demo/"
      }}>{`Demo`}</a>{` einer Karte mit Zoom- und Verschiebe-Buttons an:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "852px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWUlEQVQozyWSuXLaCABA9f/VNimzW8TjA6/PxIDxosNCgITQfSEJXYAFgiSTmSTt2/Fu+Zo3r3jCoduRFRHbOqXdNGxzB/+twNx9Y7HpsDYn0oOFvQ9wNkf2ocVXVeEoSRxil7CL2XUVVVtz+vEdIYx8JvMnktzCnKvYkyGa/kgvTDlbZpy5G+5sEVn5wMtYQ5m+orz+xfTxT/zrG/rZE1m1RHEHlMcVQlFk+K6O/eYSbR3KJGG9FNG8Eb244tIIGIkqptXnOV1w7r0xXutMKhX/4ZHcnpNsQ0z5I+0hQVgYExxTwpqMMQONKJhRhh6e+oAoDXl+HnGlGlyFa4aRxK3rcuMVvMYxUuywFr9QxR7T1RPlKUAwFyrOVCZQZTxzjmVJNHVG7WrED38wij0uoh09t+TOi5gWEjdBzq1XcB9n6KshrSwjexJ5lyEE7hBHV/EdHd8yqLIVUWLQOBKVcoZp9vmyyrlb5ly6FS+Jh5ppXHnFf/yYx0TqkER6Zfv9iOBZc/T5GGO6IG9TVk2As9DwHI06S8jsAaIzoBcn/0u8mufI4CWV6fkVF25NP4lJ531+HbYIjmEReCKWOSTwF2Spg2Vo2EuFJFVZZTqO+RnRVriNNlz5Jdd+xbhQ+Sd/L23ohTUTx+TnfouwnM4ILBfTuGemDNAVGWM2wl0MkOZ/Y8UzwsJm8vmae1nhwi/5ZOec2xliNuYpNjmzS87tiPbbEWFXNnSbjqbMWecm6zSi3QU0RYif6VR1wunQ0ZYpkTPD3h6YNu/DH8i7Pc1pQ/62J2lafvz8zb93dquwo+uAegAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot einer interaktiven Karte mit hoch/runter/links/rechts Schaltflächen.",
          "title": "Screenshot einer interaktiven Karte mit hoch/runter/links/rechts Schaltflächen.",
          "src": "/static/298f12c58d9a122c618d2f1de28f882f/47ff6/screenshot-map-with-move-controls.png",
          "srcSet": ["/static/298f12c58d9a122c618d2f1de28f882f/5a46d/screenshot-map-with-move-controls.png 300w", "/static/298f12c58d9a122c618d2f1de28f882f/0a47e/screenshot-map-with-move-controls.png 600w", "/static/298f12c58d9a122c618d2f1de28f882f/47ff6/screenshot-map-with-move-controls.png 852w"],
          "sizes": "(max-width: 852px) 100vw, 852px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Alexander Lehner`}</em></p>
    <p>{`Wie der Screenshot oben zeigt, wird die fokussierte Schaltfläche durch einen dicken Umriss und einen Farbwechsel deutlich
hervorgehoben. Lest den nächsten Abschnitt, um mehr über barrierefreie Fokusindikatoren zu erfahren.`}</p>
    <h3>{`Anwendungsfall: Gut sichtbare Fokus-Indikatoren`}</h3>
    <p>{`Gut wahrnehmbare Fokus-Indikatoren zählen zu den grundlegenden Features von barrierefreien Websites und Apps. Das
Erfolgskriterium `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-visible.html"
      }}>{`2.4.7 Fokus sichtbar (AA)`}</a>{` besagt:`}</p>
    <blockquote lang="en">
    Any keyboard operable user interface has a mode of operation where the <strong>keyboard focus indicator is visible</strong>.
    </blockquote>
    <p>{`Dieses Kriterium gibt es schon seit fast 15 Jahren, seit der Veröffentlichung der WCAG 2.0. Leider gibt es immer noch viele Websites,
die keine oder kaum sichtbare Fokus-Indikatoren aufweisen. Einer der Gründe dafür könnte sein, dass die WCAG nie normativ definiert
haben, was „sichtbar“ in der Praxis bedeutet.`}</p>
    <p>{`Das neue Erfolgskriterium `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance.html"
      }}>{`2.4.13 Fokus-Erscheinungsbild (AAA)`}</a>{`
definiert endlich die Mindestabmessungen und das Design von Fokus-Indikatoren. Sie sollten ausreichend groß sein und einen
Kontrast von mindestens 3:1 zwischen dem fokussierten und dem unfokussierten Zustand eines Elements bieten.`}</p>
    <p>{`Oft lehnt eine Kundin oder ein Designer starke Fokus-Indikatoren aus ästhetischen Gründen ab. In diesem Fall empfehle ich die
Verwendung der CSS-Pseudoklasse `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{`. Damit könnt ihr Fokus-Indikatoren nur dann anzeigen,
wenn sie benötigt werden (z.B. bei der Tastaturnavigation). Seht euch meine Demo an,
die `}<InlineCode mdxType="InlineCode">{`:focus`}</InlineCode>{` mit `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` vergleicht.`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/a11yfeatures" title="Demo zur die focus-visible Pseudo-Klasse" loading="lazy"></iframe>
    <p>{`Wenn ihr weitere Details erfahren wollte, dann lest meinen Artikel
`}<a parentName="p" {...{
        "href": "/de/highly-visible-keyboard-focus.de/"
      }}>{`„Gestalte deutlich sichtbaren Tastaturfokus mit :focus-visible“`}</a>{`.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG22/"
        }}>{`Web Content Accessibility Guidelines (WCAG) 2.2, W3C Recommendation, 5. Oktober 2023`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tetralogical.com/blog/2023/10/05/whats-new-wcag-2.2/"
        }}>{`What's new in WCAG 2.2 (TetraLogical)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.levelaccess.com/blog/wcag-22-aa-summary-and-checklist-for-website-owners/"
        }}>{`WCAG 2.2 AA: Summary and Checklist for Website Owners (Level Access)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-padding"
        }}>{`MDN: CSS scroll-padding`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible"
        }}>{`MDN: CSS :focus-visible`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      